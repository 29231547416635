import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { vars } from '@seed-design/design-token'
import { AppScreen } from '@stackflow/plugin-basic-ui'
import { ActivityComponentType } from '@stackflow/react'
import { AlertDialog, DialogContainer } from '@daangn/sprout-components-dialog'
import { parseAppInfoSafe } from '@daangn/webview-ua-parser'
import { openExternalBrowser } from '@daangn/webview-link-router'
import { useVerifyIdentification } from '@daangn/transfer-identification-sdk'

import { userInfoSelector } from '@src/selectors/user'
import { MenuContainer, PageContainer } from '@src/components/base/Container'
import { MenuTitle, SectionTitle } from '@src/components/base/Title'
import { InlineTextButton } from '@src/components/base/Button'
import { formatPhoneNumber, formatVerifiedAt } from '@src/utils'
import { countrySelector } from '@src/selectors/app'
import { IS_PROD } from '@src/config'
import { useFlow } from '@src/router/Routes'
import { bridge, logAnalyticsEvent } from '@src/bridge'
import { fetchWithAuth } from '@src/api/fetchWithAuth'
import {
  EMAIL_DELETE_BASE_URL,
  IDENTIFICATION_BASE_URL,
  STORAGE_KEY_EMAIL,
  TERMS_WEBVIEW_URL,
} from '@src/constants/api'
import { initStateFromMyAccount } from '@src/ducks/user'
import { storage } from '@src/api'

const appInfo = parseAppInfoSafe(window.navigator.userAgent)

const osTypeLowerCase = appInfo?.osType.toLowerCase() || 'desktop'

const isAvailableToMoveStore = () => {
  if (!appInfo) return false

  return (
    (osTypeLowerCase === 'ios' && parseInt(appInfo.versionCode, 10) >= 230900) ||
    (osTypeLowerCase === 'android' && parseInt(appInfo.versionCode, 10) >= 231800)
  )
}

export type IdentityVerification = {
  birth_date: {
    year: number
    month: number
    day: number
  }
  phone_number_e164: string
  verify_time: string
}

const MyAccountPage: ActivityComponentType = () => {
  const { t } = useTranslation()
  const { push, pop } = useFlow()
  const country = useSelector(countrySelector)
  const userInfo = useSelector(userInfoSelector)
  const dispatch = useDispatch()

  const { onNextClick } = useVerifyIdentification({
    agreementUrl: `${TERMS_WEBVIEW_URL}/pay_identity`,
    bridge,
    fetchVerify: () => {
      return fetchWithAuth(`${IDENTIFICATION_BASE_URL}/identification/v2/karrot-users/me/kr/identification`)
    },
    newIdentificationScheme: IS_PROD
      ? 'karrot://identification?type=account&referrer=settings_webview&show_complete=true'
      : 'karrot.alpha://identification?type=account&referrer=settings_webview&show_complete=true',
    onIdentified: () => {
      pop()
      pop()
    },
    onMoveAgreement: () => {
      push('ActivityBottomSheetOnlyTerms', {})
    },
    onNotIdentified: () => {
      pop()
      pop()
    },
    transferIdentificationScheme: IS_PROD
      ? 'karrot://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.karrotwebview.com%2F%3Fprevious_screen_name%3Dsetting&navbar=false&scrollable=false'
      : 'karrot.alpha://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.alpha.karrotwebview.com%2F%3Fprevious_screen_name%3Dsetting&navbar=false&scrollable=false',
  })

  const [identification, setIdentification] = useState<IdentityVerification | null>(null)
  const [identificationStatus, setIdentificationStatus] = useState<
    'verified' | 'notVerified' | 'errorOccurred' | 'pending'
  >('pending')

  const [isRegistered, setIsRegistered] = useState(false)
  const isProceeding = useRef(false)

  const fetchIdentification = useCallback(async () => {
    try {
      const res = await fetchWithAuth(`${IDENTIFICATION_BASE_URL}/identification/v2/karrot-users/me/kr/identification`)

      if (!res.ok) {
        throw res
      }

      const data = await res.json()
      setIdentification(data)
      setIdentificationStatus('verified')
    } catch (e) {
      if (e.status === 404) {
        // console.error(e)
        setIdentification(null)
        setIdentificationStatus('notVerified')
        return
      }
      console.error(e)
      setIdentificationStatus('errorOccurred')
      setIdentification(null)
    }
  }, [])

  useEffect(() => {
    fetchIdentification().then(console.log).catch(console.error)
  }, [fetchIdentification])

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && isRegistered && !isProceeding.current) {
        try {
          isProceeding.current = true
          await fetchIdentification()
          isProceeding.current = false
        } catch (e) {
          console.log(e)
          isProceeding.current = false
        }
      }
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [fetchIdentification, isRegistered])

  const [shouldDialogueOpen, setShouldDialogueOpen] = useState(false)
  const [shouldUpdateDialogueOpen, setUpdateShouldDialogueOpen] = useState(false)
  const [shouldEmailDeleteDialogueOpen, setShouldEmailDeleteDialogueOpen] = useState(false)

  if (identificationStatus === 'pending') {
    return null
  }

  return (
    <AppScreen appBar={{ title: t('my_account.title') }}>
      <PageContainer>
        <section>
          <SectionTitle>{t('my_account.index.title.account_info')}</SectionTitle>
          <MenuContainer>
            <MenuTitle>{t('my_account.index.menu.email')}</MenuTitle>
            {userInfo.email && <MenuItem>{userInfo.email}</MenuItem>}
            <InlineTextButton
              onClick={() => {
                // 이메일이 있는 경우 이메일 삭제 모달 띄우기
                if (userInfo.email) {
                  setShouldEmailDeleteDialogueOpen(true)
                  return
                }

                // 이메일이 없는 경우 이메일 등록 화면 이동
                push('EmailPage', { register: true })
              }}>
              {userInfo.email ? t('my_account.email.remove') : t('common.register')}
            </InlineTextButton>
          </MenuContainer>
          <MenuContainer>
            <MenuTitle>{t('my_account.index.menu.phone_number')}</MenuTitle>
            {userInfo.phone && <MenuItem>{formatPhoneNumber(userInfo.phone, country)}</MenuItem>}
            <InlineTextButton
              onClick={async () => {
                logAnalyticsEvent({ eventName: 'client_clicked_update_phone_button_v1' })

                if (!appInfo || parseInt(appInfo.versionCode, 10) < 243101) {
                  setUpdateShouldDialogueOpen(true)
                  return
                }

                logAnalyticsEvent({
                  eventName: 'client_shown_app_screen_v1',
                  params: {
                    screen_class_name: 'PhonePage',
                    screen_name: 'update_phone',
                    service_name: 'common',
                  },
                })
                push('IdentificationPageForPhoneNumberChange', {})
              }}>
              {t('common.change')}
            </InlineTextButton>
          </MenuContainer>
          {country === 'KR' && identificationStatus !== 'pending' && identificationStatus !== 'errorOccurred' && (
            <MenuContainer>
              <MenuTitle>{t('my_account.index.menu.identity_verification')}</MenuTitle>
              {identification && <MenuItem>{formatVerifiedAt(identification.verify_time)}</MenuItem>}
              <InlineTextButton
                onClick={async () => {
                  if (identification) {
                    push('IdentityVerificationMarketPage', { identification: JSON.stringify(identification) })
                    return
                  }

                  try {
                    if (!appInfo || parseInt(appInfo.versionCode, 10) < 234600) {
                      setShouldDialogueOpen(true)
                      return
                    }

                    setIsRegistered(true)
                    onNextClick()
                  } catch (e) {
                    console.log(e)
                  }
                }}>
                {identification && identificationStatus === 'verified' ? t('common.confirm') : t('common.register')}
              </InlineTextButton>
            </MenuContainer>
          )}
        </section>
        <DialogContainer
          onOutsideClick={() => {
            setShouldDialogueOpen(false)
          }}>
          {shouldDialogueOpen &&
            (isAvailableToMoveStore() ? (
              <AlertDialog
                description={t('my_account.identity_Verification.update_notification.description')}
                onPrimaryAction={() => {
                  if (osTypeLowerCase === 'ios') {
                    openExternalBrowser({
                      url: 'https://apps.apple.com/app/id1018769995',
                      stage: 'production',
                    })
                    setShouldDialogueOpen(false)
                    return
                  }

                  if (osTypeLowerCase === 'android') {
                    openExternalBrowser({
                      url: 'https://play.google.com/store/apps/details?id=com.towneers.www',
                      stage: 'production',
                    })
                    setShouldDialogueOpen(false)
                    return
                  }
                }}
                onSecondaryAction={() => {
                  setShouldDialogueOpen(false)
                }}
                primaryActionLabel={
                  osTypeLowerCase === 'ios'
                    ? t('my_account.identity_Verification.update_notification.move_to_app_store')
                    : t('my_account.identity_Verification.update_notification.move_to_play_store')
                }
                secondaryActionIntent="neutral"
                secondaryActionLabel={t('my_account.identity_Verification.update_notification.seccoindary_action')}
                title={t('my_account.identity_Verification.update_notification.title')}
                maxWidth="80%"
                UNSAFE_style={{ whiteSpace: 'pre-line' }}
              />
            ) : (
              <AlertDialog
                description={t('my_account.identity_Verification.update_notification.description')}
                onPrimaryAction={() => {
                  setShouldDialogueOpen(false)
                }}
                primaryActionLabel={t('common.confirm')}
                title={t('my_account.identity_Verification.update_notification.title')}
                maxWidth="80%"
                UNSAFE_style={{ whiteSpace: 'pre-line' }}
              />
            ))}
          {shouldUpdateDialogueOpen && (
            <AlertDialog
              description={t('my_account.index.message.update_app_to_change_phone')}
              onPrimaryAction={() => {
                setUpdateShouldDialogueOpen(false)
              }}
              primaryActionLabel={t('common.confirm')}
              title={t('my_account.index.message.need_update_app')}
              maxWidth="80%"
              UNSAFE_style={{ whiteSpace: 'pre-line' }}
            />
          )}
          {shouldEmailDeleteDialogueOpen && (
            <AlertDialog
              description={t('my_account.email.dialogue.remove.description')}
              onPrimaryAction={async () => {
                try {
                  const res = await fetchWithAuth(
                    `${EMAIL_DELETE_BASE_URL}/user/v3/karrot-users/me/email-verification`,
                    {
                      method: 'DELETE',
                    }
                  )
                  if (res.status === 200) {
                    const email = storage.getItemFromLocal(STORAGE_KEY_EMAIL) || ''
                    dispatch(initStateFromMyAccount({ email }))
                    setShouldEmailDeleteDialogueOpen(false)
                    return
                  }

                  const errorData = await res.json()
                  await bridge.openToast({ toast: { body: errorData.message.text } })

                  setShouldEmailDeleteDialogueOpen(false)
                } catch (e) {
                  await bridge.openToast({ toast: { body: t('hoian.errors.unpredicted_error') } })
                  setShouldEmailDeleteDialogueOpen(false)
                }
              }}
              primaryActionLabel={t('common.confirm')}
              secondaryActionLabel={t('common.cancel_modal')}
              onSecondaryAction={() => {
                setShouldEmailDeleteDialogueOpen(false)
              }}
              title={t('my_account.email.dialogue.remove.title')}
              maxWidth="80%"
              UNSAFE_style={{ whiteSpace: 'pre-line' }}
            />
          )}
        </DialogContainer>
      </PageContainer>
    </AppScreen>
  )
}

export default MyAccountPage

const MenuItem = styled.div`
  margin: 8px 0 0;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray600};
`
